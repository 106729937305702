/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useEffect, useState } from 'react';

import { Providers } from './components/Providers';
import { App } from './components/App';

export const FeatureApp: React.FC<FeatureAppProps> = ({
  children,
  serviceLocale,
  serviceFootnote,
  serviceI18n,
  serviceTracking,
  serviceContent,
}: FeatureAppProps) => {
  const [content, setContent] = useState(serviceContent?.getContent() as unknown as ContentAem);
  useEffect(() => {
    serviceContent?.onContentChange(() => {
      setContent(serviceContent?.getContent() as unknown as ContentAem);
    });
  }, [content]);

  return (
    <div data-fake-update="1" data-testid="FeatureApp" className="fa-carousel">
      <Providers
        i18nData={serviceI18n}
        i18nLanguage={serviceLocale.language}
        serviceTracking={serviceTracking}
        serviceFootnote={serviceFootnote}
        content={content}
      >
        {children}
        <App />
      </Providers>
    </div>
  );
};

export default FeatureApp;
