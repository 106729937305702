import React, { createContext } from 'react';
import { ThemeProvider as ProviderTheme, GlobalStyles } from '@oneaudi/unified-web-common';
import { ThemeProvider } from '@audi/audi-ui-react';
import { ThemeName } from '@oneaudi/unified-web-common/dist/cjs/components/theme-provider/theme';
import ProviderI18n from '@oneaudi/i18n-context';

import { FootnoteContextProvider as ProviderFootnote } from '@oneaudi/feature-app-utils';

import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';

import { adapterContent } from '../adapterContent';

export const ContextServiceTracking = createContext<TrackingServiceV2 | undefined>(undefined);
export const ContextServiceContent = createContext<ContentApp | undefined>(undefined);

const ProviderTracking = ContextServiceTracking.Provider;
const ProviderContent = ContextServiceContent.Provider;

export const Providers: React.FC<ProvidersProps> = ({
  children,
  content,
  i18nData,
  i18nLanguage,
  serviceTracking,
  serviceFootnote,
}: ProvidersProps) => {
  const adaptedContent = adapterContent(content);

  let theme = 'light';
  if (adaptedContent && adaptedContent.theme) {
    theme = adaptedContent.theme;
  }

  return (
    <div data-testid="Providers">
      <GlobalStyles />
      <ProviderTheme theme={{ name: theme as ThemeName }}>
        <ThemeProvider>
          <ProviderFootnote footnoteReferenceService={serviceFootnote.getDefaultScopeRefService()}>
            <ProviderContent value={adaptedContent}>
              <ProviderTracking value={serviceTracking}>
                <ProviderI18n i18nData={i18nData} language={i18nLanguage} scopes={['fa.carousel']}>
                  {children}
                </ProviderI18n>
              </ProviderTracking>
            </ProviderContent>
          </ProviderFootnote>
        </ThemeProvider>
      </ProviderTheme>
    </div>
  );
};

export default Providers;
