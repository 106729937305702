/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useRef, useState, useEffect } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { SplideSlide, SplideTrack, Splide } from '@splidejs/react-splide';

import {
  TextButton as AudiButton,
  Text as AudiText,
  Dialog,
  Headline,
  Pagination,
  TextButton,
} from '@oneaudi/unified-web-components';
import styled from 'styled-components';

import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { Section } from './style';

import { carouselState } from '../carouselState';
import { urlQueryStringToObj } from '../urlQueryStringToObj';
import { objToStr } from '../objToStr';

import { removeRole } from '../removeRole';
import { messages } from '../../i18n/messages';

const Div = styled.div`
  a {
    padding: 0;
    span {
      gap: 0px;
    }
  }
`;
const Span = styled.span`
  > div {
    padding: 0 96px;
    overflow: hidden !important;

    @media (max-width: 374px) {
      padding: 0 16px;
    }
    @media (min-width: 375px) and (max-width: 767px) {
      padding: 0 28px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      padding: 0 40px;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      padding: 0 40px;
    }
  }
  ul {
    display: flex;
    text-decoration: none !important;
    padding: 0 !important;
    margin: 0 !important;
    li {
      margin-right: 24px;
      list-style-type: none !important;
    }
  }
`;
const CustomCta = styled(TextButton)`
  padding: 0 !important;
  align-items: baseline !important;
`;

const carouselSlides = (
  slides: ContentApp['slides'],
  slideWidth: number,
  refSlideTrack: HTMLDivElement | null,
) => {
  return slides.map((slide, index) => {
    const {
      slideTitle,
      slideImageAssetPath,
      slideImageAlt,
      slideDescription,
      slideDisclaimer,
      slideCtaText,
      slideCtaUrl,
      slideCtaUrlTarget,
    } = slide;
    const key = `${slideTitle || 'slideTitle'}:${slideImageAlt || 'slideImageAlt'}:${slideImageAssetPath || 'slideImageAssetPath'}:${index}`;

    const [slideImageSrc, slideImageSrcQueryParams] = slideImageAssetPath.split('?');
    const [open, setOpen] = useState(false);
    const slideImageSrcQueryParamsObj = urlQueryStringToObj(slideImageSrcQueryParams);
    slideImageSrcQueryParamsObj.preferwebp = 'true';
    slideImageSrcQueryParamsObj.width = `${slideWidth}`;
    const imgSrc = `${slideImageSrc}?${objToStr(slideImageSrcQueryParamsObj)}`;

    useEffect(() => {
      removeRole(refSlideTrack, `splide01-slide0${index + 1}`, 'role');
    }, [refSlideTrack]);

    return (
      <SplideSlide key={key}>
        <UeContainer propertyPath={`slides.${index}`} label="Slide" as="div">
          <UeReference propertyPath={`slides.${index}`} behavior="component">
            <Div
              data-testid="CarouselSlide"
              className="faCarousel__slide"
              style={{ width: `${slideWidth}px` }}
            >
              <UeElement type="media" property="slideImageAsset" label="Image">
                <img
                  data-testid="CarouselSlideImage"
                  className="faCarousel__image"
                  src={imgSrc}
                  alt={slideImageAlt}
                  width={slideWidth}
                  height={slideWidth}
                />
              </UeElement>
              {(slideTitle || slideDescription) && (
                <div className="faCarousel__copy">
                  {slideTitle && (
                    <UeElement type="text" property="slideTitle" label="Slide Title">
                      <AudiText
                        variant={{ order: '1', style: 'bold', type: 'copy' }}
                        data-testid="CarouselSlideTitle"
                        className="faCarousel__slideTitle"
                      >
                        {renderTextWithFootnotesReferences(slideTitle)}
                      </AudiText>
                    </UeElement>
                  )}

                  {slideDescription && (
                    <UeElement type="text" property="slideDescription" label="Slide Description">
                      <AudiText
                        variant={{ order: '1', style: 'normal', type: 'copy' }}
                        data-testid="CarouselSlideDescription"
                        className="faCarousel__slideDescription"
                      >
                        {renderTextWithFootnotesReferences(slideDescription)}
                      </AudiText>
                    </UeElement>
                  )}
                </div>
              )}

              {slideCtaUrl && slideCtaText && (
                <UeElement type="text" property="slideCtaText" label="CTA Text">
                  <AudiButton
                    data-testid="CarouselSlideCta"
                    className="faCarousel__cta"
                    variant="tertiary"
                    size="sm"
                    href={slideCtaUrl}
                    target={slideCtaUrlTarget === '2' ? '_blank' : '_self'}
                    icon="forward"
                    iconPosition="trailing"
                  >
                    {renderTextWithFootnotesReferences(slideCtaText)}
                  </AudiButton>
                </UeElement>
              )}

              {slideDisclaimer && (
                <Dialog
                  open={open}
                  onCloseButtonAriaLabel="Close"
                  onClose={() => setOpen(!open)}
                  variant="focus-large"
                  triggerButton={
                    <CustomCta
                      data-testid="CarouselSlideCta"
                      variant="tertiary"
                      size="sm"
                      icon="forward"
                      iconPosition="trailing"
                      onClick={() => setOpen(!open)}
                      className="faCarouselDisclaimer__cta"
                    >
                      {useI18n(messages.disclaimerCta)}
                    </CustomCta>
                  }
                >
                  <UeElement
                    type="text"
                    property="slideDisclaimer"
                    label="Disclaimer text displayed at bottom of slide in a smaller font size"
                  >
                    <AudiText
                      variant={{ order: '1', style: 'normal', type: 'copy' }}
                      data-testid="CarouselSlideDisclaimer"
                      className="faCarousel__slideDisclaimer"
                    >
                      {renderTextWithFootnotesReferences(slideDisclaimer)}
                    </AudiText>
                  </UeElement>
                </Dialog>
              )}
            </Div>
          </UeReference>
        </UeContainer>
      </SplideSlide>
    );
  });
};

export const Carousel = ({ data }: { data: ContentApp }) => {
  const { carouselTitle, slides, theme } = data;

  const refSplide = useRef<Splide>(null);
  const refSlideTrack = useRef<HTMLDivElement>(null);

  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselPadding, setCarouselPadding] = useState(0);
  const [slideWidth, setSlideWidth] = useState(300);
  const [slideActive, setSlideActive] = useState(0);
  const [slidesPerPage, setSlidesPerPage] = useState(1);
  const [paginationShow, setPaginationShow] = useState(true);
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationPage, setPaginationPage] = useState<number>(1);

  const slideGap = 24;

  const updateCarouselState = (padding: number, targetSlide?: number) => {
    if (!refSlideTrack?.current || !refSplide?.current?.splide) {
      throw new Error('Missing refSlideTrack or refSplide');
    }
    // Get new Carousel state based on width
    const out = carouselState({
      carouselWidth: refSlideTrack.current.clientWidth,
      carouselListWidth: refSlideTrack.current.querySelector('.splide__list')?.scrollWidth || 0,
      carouselPadding: padding,
      slideActive:
        typeof targetSlide === 'undefined' ? refSplide.current.splide.index : targetSlide,
      slidesTotal: slides.length,
    });

    // Update state
    setCarouselWidth(refSlideTrack.current.clientWidth);
    setSlideWidth(out.slideWidth);
    setSlideActive(out.slideActive);
    setSlidesPerPage(out.slidesPerPage);
    setPaginationCount(out.paginationCount);
    setPaginationPage(out.paginationPage);
    setPaginationShow(out.paginationShow);

    return out.slideActive;
  };

  // Connect Audi Pagination to Splide Carousel

  const onClickNext = () => {
    if (refSplide?.current?.splide) {
      const newSlide = updateCarouselState(
        carouselPadding,
        refSplide.current.splide.index + slidesPerPage,
      );
      // Move carousel
      refSplide.current.splide.go(newSlide);
    }
  };

  const onClickPrev = () => {
    if (refSplide?.current?.splide) {
      const newSlide = updateCarouselState(
        carouselPadding,
        refSplide.current.splide.index - slidesPerPage,
      );
      // Move carousel
      refSplide.current.splide.go(newSlide);
    }
  };

  // Update Carousel state on resize
  useEffect(() => {
    if (refSplide?.current?.splide) {
      refSplide.current.splide.on('resized', () => {
        if (refSlideTrack?.current?.clientWidth !== carouselWidth) {
          updateCarouselState(carouselPadding);
        }
      });
    }
    // Cleanup
    return () => {
      if (refSplide?.current?.splide) {
        refSplide.current.splide.off('resized');
      }
    };
  }, [refSplide, carouselPadding]);

  useEffect(() => {
    if (refSplide?.current?.splide) {
      refSplide.current.splide.on('updated', ({ padding }) => {
        const { right = 0 } = padding as { [key: string]: number };
        setCarouselPadding(right);
      });
    }
    // Cleanup
    return () => {
      if (refSplide?.current?.splide) {
        refSplide.current.splide.off('updated');
      }
    };
  }, [refSplide]);

  // Update Carousel state on drag
  useEffect(() => {
    if (refSplide?.current?.splide) {
      refSplide.current.splide.on('active', () => {
        if (refSplide?.current?.splide) {
          updateCarouselState(carouselPadding, refSplide.current.splide.index);
        }
      });
    }
    // Cleanup
    return () => {
      if (refSplide?.current?.splide) {
        refSplide.current.splide.off('active');
      }
    };
  }, [refSplide, slideActive]);

  // Update Carousel state on load
  useEffect(() => {
    updateCarouselState(carouselPadding);
    if (refSlideTrack && refSlideTrack.current) {
      let st = refSlideTrack.current.querySelector('ul')?.style.display;
      if (st) {
        st = 'flex';
      }
    }
  }, []);

  // Get class names
  const rootClassNames = [];

  if (theme) {
    const themeName = theme.toLowerCase();
    if (themeName.includes('light')) {
      rootClassNames.push('faCarousel--light');
    }
    if (themeName.includes('dark')) {
      rootClassNames.push('faCarousel--dark');
    }
  }
  rootClassNames.push(carouselTitle ? 'faCarousel--withTitle' : 'faCarousel--withoutTitle');

  // Get slides
  const splideSlides = carouselSlides(slides, slideWidth, refSlideTrack?.current);

  return (
    <Section
      data-testid="Carousel"
      aria-label={carouselTitle || 'Carousel'}
      className={`faCarousel ${rootClassNames.join(' ')}`}
    >
      <UeElement type="text" property="carouselTitle" label="Title of Carousel">
        <Headline
          data-testid="CarouselTitle"
          className={`faCarousel__title ${carouselTitle ? 'faCarousel__title--set' : 'faCarousel__title--unset'}`}
          variant={{ order: '2', type: 'headline', weight: 'bold' }}
          tag="h2"
        >
          {renderTextWithFootnotesReferences(carouselTitle || '')}
        </Headline>
      </UeElement>
      <div className="faCarousel__slideTrack" ref={refSlideTrack}>
        <Splide
          hasTrack={false}
          options={{
            pagination: false,
            arrows: false,
            gap: slideGap,
            autoWidth: true,
            fixedWidth: slideWidth,
            start: slideActive,
            breakpoints: {
              374: {
                padding: { left: 16, right: 16 },
              },
              767: {
                padding: { left: 28, right: 28 },
              },
              1023: {
                padding: { left: 40, right: 40 },
              },
              1439: {
                padding: { left: 40, right: 40 },
              },
              1919: {
                padding: { left: 96, right: 96 },
              },
              1920000: {
                padding: { left: 96, right: 96 },
              },
            },
          }}
          ref={refSplide}
        >
          <UeContainer propertyPath="slides" label="Carousel Slides">
            <Span>
              <SplideTrack>{splideSlides}</SplideTrack>
            </Span>
          </UeContainer>
        </Splide>
      </div>
      <div
        data-testid="CarouselPaginationContainer"
        className={`faCarousel__paginationContainer ${paginationShow ? 'faCarousel__paginationContainer--visible' : 'faCarousel__paginationContainer--hidden'}`}
      >
        <Pagination
          current={paginationPage}
          itemCount={paginationCount}
          data-testid="CarouselPagination"
          className="faCarousel__paginationComponent"
          variant="compact"
          arrowsAlwaysEnabled
          incrementElement={{ 'aria-label': 'Next', onClick: onClickNext }}
          decrementElement={{ 'aria-label': 'Previous', onClick: onClickPrev }}
        />
      </div>
    </Section>
  );
};
