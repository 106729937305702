export const removeRole = (
  element: HTMLElement | null,
  id: string,
  attribute: string,
): HTMLElement | undefined => {
  if (element && id && attribute) {
    element.querySelector(`#${id}`)?.removeAttribute(attribute);
    return element;
  }
  return undefined;
};
