import styled from 'styled-components';
import '@splidejs/splide/dist/css/splide-core.min.css';

export const Section = styled.section`
  .faCarousel--dark {
    background-color: #000;
  }
  .faCarousel--light {
    background-color: #fff;
  }
  .faCarousel__title {
    display: flex;
    width: 100%;
    align-items: flex-start;
    box-sizing: border-box;

    font-size: 20px;
    line-height: 160%;
  }
  .faCarousel__title--unset {
    min-height: 50px;
  }
  .faCarousel__slide {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }
  .faCarousel__image {
    width: 100%;
    aspect-ratio: 1/1;
  }
  .faCarousel--light .faCarousel__image {
    background-color: #f2f2f2;
    color: #000;
  }
  .faCarousel--dark .faCarousel__image {
    background-color: #333333;
    color: #fff;
  }
  .faCarousel__copy {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .faCarousel__slideTitle {
    font-size: 16px;
    line-height: 150%;
  }
  .faCarousel__slideDescription {
    letter-spacing: 0.003px;
    font-size: 16px;
    line-height: 150%;
  }
  .faCarousel__cta {
    font-size: 12px;
    line-height: 166.667%;
  }
  .faCarousel__slideFootnote {
    letter-spacing: 0.002px;
    font-size: 12px;
    line-height: 166.667%;
  }
  .faCarousel__paginationContainer {
    padding: 24px 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .faCarousel__paginationContainer--hidden ul {
    display: none;
  }
  @media (max-width: 374px) {
    .faCarousel__title--set {
      font-size: 20px;
      line-height: 160%;
      padding-top: 16px;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 16px;
    }
  }
  @media (min-width: 375px) and (max-width: 767px) {
    .faCarousel__title--unset {
      min-height: 70px;
      padding: 0;
    }
    .faCarousel__title--set {
      font-size: 20px;
      line-height: 160%;
      padding-top: 28px;
      padding-right: 28px;
      padding-left: 28px;
      padding-bottom: 44px;
    }
    .faCarousel__paginationContainer {
      padding-top: 48px;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .faCarousel__title--unset {
      min-height: 70px;
      padding: 0;
    }
    .faCarousel__title--set {
      font-size: 20px;
      line-height: 160%;
      padding-top: 40px;
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 56px;
    }
    .faCarousel__paginationContainer {
      padding-top: 48px;
    }
    .faCarousel__paginationComponent {
      margin-right: 40px;
    }
  }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .faCarousel__title--unset {
      min-height: 104px;
      padding: 0;
    }
    .faCarousel__title--set {
      font-size: 20px;
      line-height: 160%;
      padding-top: 40px;
      padding-bottom: 32px;
      padding-right: 40px;
      padding-left: 40px;
    }
    .faCarousel__paginationContainer {
      padding-top: 48px;
    }
    .faCarousel__paginationComponent {
      margin-right: 40px;
    }
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    .faCarousel__title--set {
      font-size: 24px;
      line-height: 150%;
      padding-top: 96px;
      padding-bottom: 32px;
      padding-right: 96px;
      padding-left: 96px;
    }
    .faCarousel__title--unset {
      min-height: 128px;
      padding: 0;
    }
    .faCarousel__paginationContainer {
      padding-top: 48px;
    }
    .faCarousel__paginationComponent {
      margin-right: 96px;
    }
  }
  @media (min-width: 1920px) {
    .faCarousel__title--set {
      font-size: 24px;
      line-height: 150%;
      padding-top: 96px;
      padding-bottom: 32px;
      padding-right: 96px;
      padding-left: 96px;
    }
    .faCarousel__title--unset {
      height: 104px;
      padding: 0;
    }
    .faCarousel__paginationContainer {
      padding-top: 48px;
      padding-bottom: 24px;
    }
    .faCarousel__paginationComponent {
      margin-right: 96px;
    }
  }

  @media (min-width: 768px) {
    .faCarousel__paginationContainer {
      align-items: flex-end;
    }
  }
`;
