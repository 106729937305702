export const adapterContent = (content: ContentAem): ContentApp => {
  if (!content) {
    throw new Error('no content provided to `adapterContent`');
  }
  const { fields } = content;
  const { theme, carouselTitle, slides } = fields;
  const slidesModified = slides.map((slide) => {
    const {
      slideCtaText,
      slideCtaUrl,
      slideCtaUrlTarget,
      slideDescription,
      slideDisclaimer,
      slideImageAlt,
      slideImageAsset,
      slideTitle,
    } = slide.fields;

    return {
      slideCtaText,
      slideCtaUrl,
      slideCtaUrlTarget,
      slideDescription,
      slideDisclaimer,
      slideImageAlt,
      slideImageAssetPath: slideImageAsset.path,
      slideTitle,
    };
  });

  return {
    theme,
    carouselTitle,
    slides: slidesModified,
  };
};
